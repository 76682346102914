<template>
  <div class="page-info" v-if="formData">
    <div class="user-info">
      <div class="info-head">
        <img class="head" src="../../assets/images/sun.png" />
      </div>
      <!-- 审核 -->
      <div class="info-texts" v-if="langInfo">
        <div class="texts-group">
          <div class="texts-name" v-if="formData.User_name">
            {{ formData.User_name }}
          </div>
          <!-- 跳转 注册 -->
          <!-- <div class="texts-edit">
						<img class="edit" src="../../assets/images/icons/edit.png" />
					</div> -->
        </div>
        <!--success-->
        <div class="texts-success" v-if="formData.status === 'pending'">
          <img class="ques" src="../../assets/images/icons/ques.png" />
          {{ langInfo.newLangInfo.infoPage.pendding }}
        </div>
        <!--error-->
        <div class="texts-error" v-if="formData.status === 'deny'">
          <!-- <img class="warn" src="../../assets/images/icons/warn.png" /> -->
          {{ langInfo.newLangInfo.infoPage.fail }}
        </div>
        <!-- 登陆人信息 -->
        <div class="texts-idcard" v-if="formData.User_id">{{ langInfo.newLangInfo.infoPage.userId }}:{{ formData.User_id }}</div>
        <div class="texts-date" v-if="formData.create_time">
          {{ langInfo.newLangInfo.infoPage.regDate }}:
          {{ formData.create_time.substring(0, 10) }}
        </div>
        <!--success-->
        <div class="texts-desc" v-if="formData.status === 'pending'">
          {{ langInfo.newLangInfo.infoPage.pendTitle }}
        </div>
        <!--error-->
        <div class="texts-desc" v-if="formData.status === 'deny'">
          {{ langInfo.newLangInfo.infoPage.failTitle }}
        </div>
      </div>
    </div>
    <!-- 优惠卷 -->
    <div class="coupon-info" v-if="langInfo">
      <div class="coupon-view">
        <div class="coupon-new" v-if="formData.Nation === 'TH'" @click="handToForm('th')">
          <h3 class="coupon-center">กิจกรรมพิเศษสำหรับสมาชิก</h3>
          <img class="coupon" style="border-radius: 18px" src="../../assets/images/imgs/TH.jpg" />
        </div>
        <div class="coupon-new" v-if="formData.Nation === 'ID'" @click="handToForm('id')">
          <h3 class="coupon-center">Aktivitas Eksklusif Anggota</h3>
          <img class="coupon" style="border-radius: 18px" src="../../assets/images/imgs/ID.jpg" />
        </div>
        <div class="coupon-new" v-if="formData.Nation === 'MY'" @click="handToForm('my')">
          <h3 class="coupon-center">Member Exclusive Activity</h3>
          <img class="coupon" style="border-radius: 18px" src="../../assets/images/imgs/MY.jpg" />
        </div>
        <div class="coupon-new" v-if="formData.Nation === 'PH'" @click="handToForm('ph')">
          <h3 class="coupon-center">Member Exclusive Activity</h3>
          <img class="coupon" style="border-radius: 18px" src="../../assets/images/imgs/PH.jpg" />
        </div>
        <div class="coupon-new" v-if="formData.Nation === 'SG'" @click="handToForm('sg')">
          <h3 class="coupon-center">Member Exclusive Activity</h3>
          <img class="coupon" style="border-radius: 18px" src="../../assets/images/imgs/SG.jpg" />
        </div>
        <div class="coupon-new" v-if="formData.Nation === 'VN'" @click="handToForm('vn')">
          <h3 class="coupon-center">Hoạt động dành riêng cho thành viên</h3>
          <img class="coupon" style="border-radius: 18px" src="../../assets/images/imgs/VN.jpg" />
        </div>
        <!-- 2024-4-30 活动下架 -->
<!-- 
        <div class="coupon-new" style="margin-top: 12px" @click="handToLink">
          <img class="coupon" style="border-radius: 18px" v-if="formData.Nation === 'ID'" src="../../assets/images/imgs/ID.png" />
          <img class="coupon" style="border-radius: 18px" v-else-if="formData.Nation === 'MY'" src="../../assets/images/imgs/MY.png" />
          <img class="coupon" style="border-radius: 18px" v-else-if="formData.Nation === 'PH'" src="../../assets/images/imgs/PH.png" />
          <img class="coupon" style="border-radius: 18px" v-else-if="formData.Nation === 'SG'" src="../../assets/images/imgs/SG.png" />
          <img class="coupon" style="border-radius: 18px" v-else-if="formData.Nation === 'TH'" src="../../assets/images/imgs/TH.png" />
          <img class="coupon" style="border-radius: 18px" v-else-if="formData.Nation === 'VN'" src="../../assets/images/imgs/VN.png" />
        </div> -->

        <!--新版-->
        <!--<div class="coupon-tips" v-if="formData.Nation!='ID'">
					{{ langInfo.newLangInfo.infoPage.activity }} 
				</div>-->

        <!-- 分享评论获得代金卷 -->
        <!-- <div class="coupon-title" @click="handCollect('')">
					{{ langInfo.newLangInfo.infoPage.shareText }}
					<img class="share" src="../../assets/images/icons/share.png" />
				</div>
				<div class="coupon-item" style="margin-top: 24px">
					<div class="item-info">
						<div class="info-text1">{{ langInfo.newLangInfo.infoPage.$20 }}</div>
						<div class="info-line"></div>
						<div class="info-text1">{{ langInfo.newLangInfo.infoPage.unit }}</div>
					</div>
					<div class="item-image image1" @click="handCollect('')">
						{{ langInfo.newLangInfo.infoPage.collect }}
						<img class="right-icon" src="../../assets/images/right.png" />
					</div>
				</div>
				<div class="coupon-item">
					<div class="item-info">
						<div class="info-text1">{{ langInfo.newLangInfo.infoPage.$50 }}</div>
						<div class="info-text1">{{ langInfo.newLangInfo.infoPage.unit }}</div>
					</div>
					<div class="item-image image2" @click="handCollect('2')">
						{{ langInfo.newLangInfo.infoPage.collect }}
						<img class="right-icon" src="../../assets/images/right.png" />
					</div>
				</div> -->
      </div>
      <div class="fixed-footer">
        <!-- 购买 跳转  -->
        <div class="footer-app" v-if="showDataInfo && showDataInfo.urlList.length">
          <template v-for="(item, index) in showDataInfo.urlList">
            <img class="coupon" v-if="item.name == 'Shopee'" :key="index" :src="require('../../assets/shopee.png')" alt="Shopee Simplus" @click="handOpen(item.url)" />
            <img class="coupon" v-if="item.name == 'Lazada'" :key="index" :src="require('../../assets/lazada.png')" alt="TikTok Simplus" @click="handOpen(item.url)" />
            <img class="coupon" v-if="item.name == 'TikTok'" :key="index" :src="require('../../assets/tt.png')" alt="TikTok Simplus" @click="handOpen(item.url)" />
            <img class="coupon" v-if="item.name == 'Tokopedia'" :key="index" :src="require('../../assets/Tokopedia.png')" alt="Tokopedia" @click="handOpen(item.url)" />
          </template>
          <!--泰国的 lin  替换 src-->
          <!-- <img class="coupon" v-if="formData.Nation==='TH'" :src="require('../../assets/Tokopedia.png')"
						alt="Tokopedia" @click="handOpen('https://lin.ee/S1d5PiN')" /> -->
        </div>
        <div class="footer-copy"><img class="icon" src="../../assets/images/icons/click.jpg" /> Click to Shop</div>
      </div>
    </div>
    <!--  泰国弹窗 -->
    <van-popup v-model.sync="showMsg" :close-on-click-overlay="false" :overlay-style="{ background: 'rgba(0,0,0,0.4)' }" :round="true">
      <div class="msg-box">
        <div class="msg-close" @click="showMsg = false">
          <van-icon name="close" />
        </div>
        <div class="msg-logo">
          <img class="logo" src="../../assets/images/success.png" />
        </div>
        <!-- 提交成功提示 -->
        <div class="msg-title">ขอบคุณสำหรับการเข้าร่วม</div>
        <!-- Lazada -->
        <div class="msg-content">Lazada:<a class="link-text" href="https://bit.ly/3MrGtXG">คลิกเพื่อรับคูปอง</a></div>
        <!-- Shopee -->
        <div class="msg-content">Shopee:<span class="link-text" @click="handCopy('SIMPNPS50')">SIMPNPS50</span><b>⬅(คลิกเพื่อคัดลอก)</b></div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { List } from "../../util/en_config_new";
import { langData } from "../../util/lang_config_new";
import { Popup, Toast, Icon } from "vant";
export default {
  components: {
    [Popup.name]: Popup,
    [Icon.name]: Icon,
  },
  data() {
    return {
      formData: {},
      langInfo: null,
      showDataInfo: {
        urlList: [],
      }, //包含手机号校验正则
      showMsg: false,
      linkObj: {
        TH: "/wp/application-form-th/",
        VN: "/wp/application-form-vn/",
        SG: "/wp/application-form-sg/",
        PH: "/wp/application-form-ph/",
        MY: "/wp/application-form-my/",
        ID: "/wp/application-form-id/",
        // VN: "/wp/wp-admin/edit.php?post_type=page&orderby=title&order=asc",
        // SG: "/wp/wp-admin/edit.php?post_type=page&orderby=title&order=asc",
        // PH: "/wp/wp-admin/edit.php?post_type=page&orderby=title&order=asc",
        // MY: "/wp/wp-admin/edit.php?post_type=page&orderby=title&order=asc",
        // ID: "/wp/wp-admin/edit.php?post_type=page&orderby=title&order=asc",
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      if (localStorage.getItem("NationName") && localStorage.getItem("LoginInfo")) {
        this.formData = JSON.parse(localStorage.getItem("LoginInfo"));
        this.formData.Nation = localStorage.getItem("NationName");
        this.langInfo = this.setLang();
        this.getUserInfo();
      } else {
        this.$router.replace("/index");
      }
    },
    getUserInfo() {
      this.axios
        .get(`/api/search/`, {
          params: {
            nation: this.formData.Nation,
            Email: this.formData.User_email,
          },
        })
        .then((res) => {
          let resstr = res.data;
          if (parseInt(resstr.code) === 200) {
            if (resstr.msg[0].User_id) {
              Toast.loading({
                duration: 1000,
                message: "sign in...",
                forbidClick: true,
                loadingType: "spinner",
              });
              localStorage.setItem("NationName", resstr.msg[0].Nation);
              localStorage.setItem("LoginInfo", JSON.stringify(resstr.msg[0]));
              localStorage.setItem("LoginToken", resstr.access_token);
              window.document.cookie = `_at=${resstr.access_token};path=/`;
            }
          }
        })
        .catch((err) => {
          // eslint-disable-next-line
          console.log(err);
        });
    },
    //是否提交过问卷
    isSubForm(callback) {
      this.axios
        .get(`/api/is_questionnaire_investigation/`, {
          params: {
            login_user: this.formData.User_email,
          },
        })
        .then((res) => {
          let resstr = res.data;
          if (parseInt(resstr.code) === 200) {
            callback(resstr.msg ? true : false);
          } else {
            // eslint-disable-next-line
            Toast(resstr.msg);
          }
        })
        .catch((err) => {
          // eslint-disable-next-line
          console.log(err);
        });
    },
    handToForm(str) {
      // if (
      // 	this.formData.status === "pending" ||
      // 	this.formData.status === "deny"
      // ) {
      // 	Toast(this.langInfo.newLangInfo.infoPage.only);
      // 	return;
      // }
      this.isSubForm((res) => {
        if (!res) {
          // this.$router.push(`/thform`);
          window.open(`/wp/nps-${str}`,"_self", "");
        } else {
          this.showMsg = true;
        }
      });
    },
    handOpen(url) {
      location.href = url;
    },
    handCollect(e) {
      if (this.formData.status === "pending" || this.formData.status === "deny") {
        Toast(this.langInfo.newLangInfo.infoPage.only);
        return;
      }
      // if (this.formData.status === "deny") {
      // 	Toast(this.langInfo.newLangInfo.infoPage.failTitle);
      // 	return;
      // }
      this.$router.push(`/form${e}`);
    },
    handToLink() {
      console.log(this.linkObj[this.formData.Nation]);
      // window.location.herf = this.linkObj[this.formData.Nation];
      window.open(this.linkObj[this.formData.Nation], "_self", "");
    },
    setLang() {
      let Info = langData.filter((res) => res.Nation == this.formData.Nation)[0];
      this.showDataInfo = List.filter((res) => res.Nation == this.formData.Nation)[0];
      return Info == undefined ? langData[0] : Info;
    },
    handCopy(text) {
      // text是复制文本
      // 创建input元素
      const el = document.createElement("input");
      // 给input元素赋值需要复制的文本
      el.setAttribute("value", text);
      // 将input元素插入页面
      document.body.appendChild(el);
      // 选中input元素的文本
      el.select();
      // 复制内容到剪贴板
      document.execCommand("copy");
      // 删除input元素
      document.body.removeChild(el);
      Toast("คัดลอกสำเร็จ , ไปที่ Shopee เพื่อใช้คูปอง");
    },
  },
};
</script>

<style lang="less">
.page-info {
  width: 100vw;
  // height: 100vh;
  background: #4e5e9a;
  position: relative;

  .user-info {
    padding: 48px 24px 24px;
    display: flex;
    //align-items: center;

    .info-head {
      position: relative;
      width: 75px;
      height: 75px;

      .head {
        width: 75px;
        height: 75px;
        border-radius: 50%;
      }
    }

    .info-texts {
      padding-left: 16px;

      .texts-group {
        display: flex;
        align-items: center;

        .texts-name {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 600;
          color: #ffffff;
        }

        .texts-edit {
          padding: 0px 12px;

          .edit {
            width: 14px;
            height: 14px;
          }
        }
      }

      .texts-success {
        width: fit-content;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        padding: 0px 6px;
        background: #19abde;
        border-radius: 12px;
        margin: 6px 0px;
        display: flex;
        align-items: center;

        .ques {
          width: 14px;
          height: 14px;
          margin-right: 4px;
        }
      }

      .texts-error {
        width: fit-content;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        padding: 0px 6px;
        background: #ff3052;
        border-radius: 12px;
        margin: 6px 0px;
        display: flex;
        align-items: center;

        .warn {
          width: 12px;
          height: 12px;
          margin-right: 4px;
        }
      }

      .texts-idcard {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        padding: 4px 0px;
      }

      .texts-date {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        padding: 4px 0px;
      }

      .texts-desc {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 500;
        color: #ffffff;
      }
    }
  }

  .coupon-info {
    // position: absolute;
    // bottom: 0px;
    width: 100vw;
    height: 80vh;
    border-radius: 24px 24px 0px 0px;
    background: #ffffff;

    .coupon-view {
      padding: 24px 12px;
      position: relative;
      min-height: 60%;
      display: flex;
      align-items: center;
      flex-direction: column;


      .coupon-tips {
        width: fit-content;
        padding: 4px 2px;
        font-size: 22px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 600;
        color: #313133;
        border-bottom: 2px solid #f4f5f6;
      }

      .coupon-new {
        .coupon-center {
          padding-bottom: 10px;
          text-align: center;
        }

        .coupon {
          width: 100%;
          height: auto;
          border-radius: 18px;
          overflow: hidden;
        }
      }

      .coupon-title {
        padding: 0px 24px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 600;
        color: #313133;
        text-align: center;
        display: flex;
        align-items: center;

        .share {
          width: 36px;
          height: 36px;
        }
      }

      .coupon-item {
        margin: 12px 0px;
        height: 100px;
        background: #f4f5fa;
        border-radius: 12px;
        display: flex;
        align-items: center;

        .item-info {
          width: 60%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .info-text1 {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 700;
            color: #000000;
          }

          .info-line {
            width: 100px;
            border-bottom: 1px dashed #616266;
          }

          .info-text2 {
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 600;
            color: #000000;
          }
        }

        .item-image {
          width: 40%;
          height: 100px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 600;
          color: #ffffff;

          .right-icon {
            transform: translate(2px, 1px) scale(0.8);
            width: 12px;
            height: 12px;
          }
        }

        .image1 {
          background: url("../../assets/images/cp-1.png") no-repeat;
          background-size: 100% 100%;
        }

        .image2 {
          background: url("../../assets/images/cp-2.png") no-repeat;
          background-size: 100% 100%;
        }
      }
    }

    .fixed-footer {
      width: 100vw;
      // position: absolute;
      // bottom: 88px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .footer-app {
        display: flex;
        justify-content: center;

        .coupon {
          width: 48px;
          height: 48px;
          margin-right: 12px;
        }

        .coupon:last-child {
          margin-right: 0px;
        }
      }

      .footer-copy {
        margin-top: 12px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #4c4c4c;
        display: flex;
        align-items: center;
        justify-content: center;

        .icon {
          width: 16px;
          height: 14px;
          margin-right: 4px;
        }
      }
    }
  }

  .msg-box {
    width: 70vw;
    height: 260px;
    border-radius: 18px;
    padding: 24px;

    .msg-close {
      text-align: right;
    }

    .msg-logo {
      text-align: center;

      .logo {
        width: 60px;
        height: 70px;
      }
    }

    .msg-title {
      text-align: center;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 600;
      color: #245bd9;
      padding: 12px;
      margin-bottom: 12px;
    }

    .msg-content {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 500;
      color: #323233;
      margin-bottom: 12px;

      .link-text {
        margin-left: 12px;
        // font-size: 16px;
        // font-family: PingFangSC-Regular, PingFang SC;
        // font-weight: 600;
        color: #245bd9;
        text-decoration: underline;
      }
    }
  }
}
</style>
